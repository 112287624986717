@import "variables";
.__dbk__container{

    &.Horizontal{

        .__dbk__gutter.Dark {
            background: #eaeaf0;
            padding: 0;

            .__dbk__dragger.Dark {
                background: #000000;
                height: 58px;
            }

        }

        .drawer-content{
            height: 100%;
            background-color: white;

            .MuiAppBar-root{
                height: 40px;
                padding: 10px;
            }

            .drawer{
                margin-left: 24px;
                margin-top: 0;
                width: 100%;

                form{
                    width: 100%;
                }

                &.form-container{
                    margin:0;

                }

                .MuiTypography-body1{
                    display: flex;

                    .sub-typography{
                        text-transform: capitalize;
                        margin-left: 5px;
                        
                        &.completado{
                            color:$success;
                        }

                        &.error{
                            color:$error;
                        }

                        &.pendiente{
                            color:$warning;
                        }
                    }
                }
            }
        }
    }
}