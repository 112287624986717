@import "variables";
.login{
    height: 100vh;
    
    .left-side{
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;

        form{
            z-index: 1;
        }

        .form-container{
            display: flex;
            flex-direction: column;
            padding: 2rem;
            border-radius: 20px;
            box-shadow: 0px 9px 16px RGB(159 162 191 / 0.35);
            
            .btn-submit{
                background-image: $linear-gradient;
                color:white;
                margin: 10px 0 0 0;
            }
        }
    }

    .bg-container{

        img{
            height: 100%;
            transform: skewX(-10deg);
        }
    }
    
    .MuiGrid-container{
        height: 100%;
        justify-content: space-around;
    }

    .MuiPaper-elevation{
        background-color: rgba(255, 255, 255, 0.8);
        -webkit-backdrop-filter: saturate(200%) blur(30px);
        backdrop-filter: $blur;
        
        .logo-container{
            display: flex;
            justify-content: left;
            align-items: baseline;
    
            img{
                margin: auto 15px;
            }
        }

        .btn-container{
            display: flex;
            justify-content: flex-end;
            align-items: baseline;

            button{
                margin: auto 15px auto 0;
                min-width: 15rem;
                text-transform: capitalize;
                min-height: 2rem;
                padding: 0.5rem 2rem;
                font-size: .9rem;
                background: $primary;
                color: #ffffff;
                border-radius: 10rem;
            }
        }
    }

    .MuiGrid-item{
        padding-top: 0px!important;
    }
}

h3{
    font-size: 1.875rem;
    line-height: 1.375;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    color: #344767;
    font-weight: 500;
    letter-spacing: 0em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #17c1e8;
    font-weight: 700;
    background-image: $linear-gradient;
    display: inline-block;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index: 1;
}

.right-side{
    height: 100vh;
    margin-right: -80px;
    border-bottom-left-radius: 10em;
    right: -2rem;
}

@media (max-width:768px) {
    
    .login{

        h3{
            font-size: 1.5rem;
        }

        .header-container{
            flex-direction: column;
        }

        .bg-container{
            position: absolute;
            padding-left: 0px;
            overflow: hidden;
            right: 0;
            padding-left: 0;
            max-width: 100%;
            img{
                opacity: 0.3;
                transform: skewX(0);
                animation-name: background-movement;
                animation-duration: 30s;
                animation-iteration-count: infinite;
            }
        }

        .form-container{
            background-color: rgba(255, 255, 255, 0.8);
            backdrop-filter: saturate(200%) blur(30px);
            padding: 30px;
            border-radius: 20px;
            box-shadow: 0px 9px 16px 2px #5b5b5b38;
        }

        .MuiGrid-item{
            padding-left: 0!important;
        }

        .btn-container{
            button{
                min-width: 0!important;
                font-size: 0.7rem!important;
            }
        }
    }
}

@media (max-width:425px){
    .login{

        .MuiPaper-elevation{
            margin: 0;
            width: 100%;
            right: 0;
            margin-top: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            .logo-container{
                justify-content: center!important;
            }
            
            .btn-container{
                
                button{
                    display: none;
                }
            }
        }

        h3{
            text-align: center;
        }
    }
}

@keyframes background-movement{
    0%{
        transform: translateX(0px);
    }
    50%{
        transform: translateX(-53%);
    }
    100%{
        transform: translateX(0%);
    }
}