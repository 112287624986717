.range-date-picker {
  height: 53px;

  z-index: 999;

  .react-daterange-picker__wrapper {
    padding: 1rem;
    border-radius: 0.3rem;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
}


