// Colors
$linear-gradient: linear-gradient(
    310deg, #2152ff, #21d4fd);

$primary: linear-gradient(310deg, #141727, #3a416f);

$blur: saturate(200%) blur(30px);

$success: #44D600;

$error: #FF1943;

$warning: #FFA319;


// Drawer Width

$drawerWidth:'240px';