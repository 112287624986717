.left-container {
  .MuiTableContainer-root {
    &.table {
      max-height: calc(100vh - 230px);
    }
  }

  .MuiContainer-root {
    max-width: 100%;
    padding: 0;

    .MuiGrid-root {
      max-width: 100%;
      margin: 0;
      padding: 0;
    }
  }
}

.tile-y {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-top;
  border-radius: 8px;
  overflow-y: auto;
}

.tile-x {
  height: 100%;
  width: 100%;

  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 8px;
  background: #121114;

  overflow-x: auto;
}

@media (max-width: 425px) {
  .left-container {
    .MuiTableContainer-root {
      max-height: 72.5vh;
    }
  }
}
