@import "variables";
@import 'rangeDatePicker';

#root {
  .container {
    height: 100vh;
    padding: 0;

    .float-button {
      z-index: 1201 !important;
      position: absolute;
      z-index: 11;
      left: 15px;
      bottom: 20px;
      transition: 0.5s;
    }

    &.sidebar-active {
      .float-button {
        transition: 0.5s;
        left: 19rem;

        .MuiButtonBase-root {
          transition: 0.5s;
          transform: rotate(-180deg);
        }
      }
    }

    .parsed-data {
      display: inline-block;
      word-break: break-all;
      word-wrap: break-word;
      white-space: pre;
      white-space: pre-wrap;
      width: 100%;
    }

    .content-container {
      margin-top: 0;
      background-color: white;

      .MuiContainer-root {
        max-width: 100%;
      }
    }

    .page-title {
      // padding: 8px 0px;

      .module-description {
        text-align: left;
        align-items: baseline;
        display: flex;
        margin-left: 30px;
      }
    }
  }

  .MuiListItem-root {
    .icon-svg {
      width: 20px;
      height: 20px !important;
      image {
        width: 20px;
        height: 20px;
        transform: translateX(0);
        transform: translateY(0);
      }
    }
  }
}

@media (max-width: 1024px) {
  .page-title {
    .module-description {
      margin-left: 15px !important;
    }
  }
}

@media (max-width: 425px) {
  .page-title {
    .MuiGrid-root {
      flex-flow: column;
    }
  }
}
